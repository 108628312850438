<template>
  <div v-if="$route.fullPath === '/member_center/setting'" class="setting-list">
    <router-link v-for="(link, idx) in links" :key="idx" :to="link.path">
      <div class="label" v-text="link.label"></div>
      <ArrowRightIcon class="icon" />
    </router-link>
    <div class="btn active log-out" @click="$store.dispatch('logOut')">
      退出登录
    </div>
  </div>
  <router-view v-else class="setting-inner"></router-view>
</template>

<script>
import ArrowRightIcon from '@/assets/icons/arrow-right.svg'

export default {
  name: 'setting',
  components: {
    ArrowRightIcon
  },
  computed: {
    links() {
      return [
        { label: '更改手机号码', path: this.$route.path + '/phone' },
        { label: '重置登录密码', path: this.$route.path + '/password' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.setting-list {
  width: 800px;
  flex-direction: column;
  color: #e0e0e0;
  font-size: 0.9rem;
  text-align: left;

  > a {
    width: 24rem;
    border-bottom: solid 1px #48485b;
    padding: 0.8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      right: 0;
      height: 0.9rem;
    }

    &:hover {
      > .icon {
        path {
          fill: $theme-color;
        }
      }
    }
  }

  .log-out {
    position: absolute;
    right: 6rem;
    top: 9rem;
  }

  @include md {
    width: unset;
    padding: 0;

    > a {
      width: 100%;
    }

    .log-out {
      position: relative;
      top: 0;
      right: 0;
      margin: 2rem auto;
      font-size: 0.8rem;
    }
  }
}

.setting-inner {
  color: #e0e0e0;
}
</style>
